window.donations = {
	setup: function () {
		var donationBlock = jQuery(".j-donation");
		if (donationBlock.length > 0) {
			donationBlock.each(function () {
				window.donations.init(jQuery(this));
			});
		}
	},
	init: function (block) {
		var slider = block.find(".j-donation__slider");
		var display = block.find(".j-donation__display");
		var input = block.find(".j-donation__input");
		var result = block.find(".j-donation__result");
		var button = block.find(".j-donation__button");
		var rate = block.data("rate");
		var donationAmount = 30;
		var sliderMin = 5;
		var sliderMax = 9999;

		// Watch for input changes
		watchInputChange();
		//  Watch for the screen to resize
		watchScreenResize();
		// Make the slider draggable (and watch dragging)
		makeSliderDraggable();
		//  Update the display on load
		updateDisplay();

		// Watch for input changes
		function watchInputChange() {
			input.on("keydown keyup change", function () {
				var value = $(this).val();
				value = parseFloat(value.replace(/,/g, ""));
				if (isNaN(value)) {
					value = 0;
				}
				donationAmount = value;
				updateDisplay();
			});
		}
		// watchScreenResize
		function watchScreenResize() {
			$(window).on("resize", function () {
				updateDisplay();
			});
		}

		// Make the slider draggable (and watch dragging)
		function makeSliderDraggable() {
			slider.draggable({
				axis: "x",
				containment: ".j-donation__display",
				drag: function (event, ui) {
					updateDonationAmountBySlidePercentage(
						calculateSliderPercentage(ui.position.left)
					);
					updateDisplay();
				},
			});
		}

		//  Update the display on load
		function updateDisplay() {
			updateInput(formatAmount(donationAmount));
			updateText(formatWeight(calculateDonationWeight()));
			updateButtonLink(donationAmount);
			updateSlider();
		}

		//  Update the input value
		function updateInput(updateValue) {
			input.val(updateValue);
			input.attr("value", updateValue);
		}
		// Update the lbs result text
		function updateText(updateValue) {
			result.text(updateValue);
		}
		// Update the slider position
		function updateSlider() {
			slider.css("left", calculateSliderPosition());
		}
		// Update the button link
		function updateButtonLink(updateValue) {
			button.attr(
				"href",
				button.data("href").replace("{{DONATION}}", updateValue)
			);
		}
		// calculate how far to move the slider based on the donation entered
		function calculateSliderPosition() {
			var percentage = Math.min(
				(donationAmount - sliderMin) / (sliderMax - sliderMin),
				1
			);
			var containerWidth = display.outerWidth() - slider.outerWidth();
			return percentage * containerWidth + "px";
		}

		// Calculate what the donation amount is based on the slider position
		function updateDonationAmountBySlidePercentage(percentage) {
			donationAmount =
				(percentage * (sliderMax - sliderMin)) / 100 + sliderMin;
		}
		// Calculate what % the slider has been moved
		function calculateSliderPercentage(position) {
			var containerWidth = display.outerWidth() - slider.outerWidth();
			return (position / containerWidth) * 100;
		}
		// Calculate how much weight the donation provideds
		function calculateDonationWeight() {
			return donationAmount / rate;
		}
		// Format the weight number into the proper format
		function formatWeight(number) {
			number = Math.trunc(number * 100) / 100;
			return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		}
		// Format the number into a Dollar amount
		function formatAmount(number) {
			return new Intl.NumberFormat("en-US").format(parseInt(number));
		}
	},
};
